import React from 'react';
import { Input, Button, Select, PaperClip } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { UserService } from 'services';
import { WhiteImage } from 'assets/img';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalCreate extends React.Component<Props> {

	state = {
		form: {
			phone: '',
			phonecode_id: '',
			alternative_phone: '',
			alternative_phonecode_id: '',
			email: '',
			selfie: '',
			score: ''
		},
		phonecodes: []
	}

	componentDidMount() {
		this.load();

		if (this.props.item) {
			const item: any = this.props.item;

			this.setState({
				form: {
					...this.state.form,
					phone: item?.phone || '',
					phonecode_id: item?.phonecode_id || '',
					alternative_phone: item?.person?.alternative_phone || '',
					alternative_phonecode_id: item?.person?.alternative_phonecode_id || '',
					email: item?.email || '',
					selfie: '',
					score: item?.credolab_score || ''
				}
			});
		}
	}

	load = async () => {
		const res: any = await UserService.admin.getPhonecodes();
		this.setState({
			phonecodes: res.phonecodes || []
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		await UserService.admin.update({
			user_id: this.props.item?.id,
			...this.state.form,
			hasFile: true,
			// @ts-ignore
			selfie: this.state.form.selfie?.value
		});
		Globals.showSuccess("Se ha actualizado el usuario correctamente");
		this.props.onClose();
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		return (
			<div id="modal-create-user">
				<div className="text-center">
					<PaperClip
						className="paperclip-image"
						value={ this.state.form.selfie }
						onChange={ (e: any) => {
							this.setState({
								form: {
									...this.state.form,
									selfie: e.target
								}
							})
						} }>
						{/*
						// @ts-ignore */}
						<img className="selected-image" src={ this.state.form.selfie?.dataURL || (this.props.item?.person?.selfie ? (Constants.BASE_STORAGE + this.props.item?.person?.selfie) : WhiteImage) } />
					</PaperClip>
				</div>
				<form onSubmit={ this.submit }>
					<div className="row">
						<div className="col-md-6">
							<Input
								value={ this.state.form.email }
								name="email"
								placeholder="Correo electrónico"
								label="Correo electrónico"
								onChange={ (e: any) => this.change(e) } />
						</div>
						<div className="col-md-6"></div>
						<div className="col-md-6">
							<p className="label">Teléfono principal</p>
							<div className="container-phone">
								<Select
									placeholder="Seleccionar"
									name="phonecode_id"
									onChange={ (e: any) => this.change(e) }
									value={ this.state.form.phonecode_id }
									options={ this.state.phonecodes.map((item: any) => {
										return {
											value: item.id,
											label: item.name
										}
									}) } />
								<Input
									type="number"
									value={ this.state.form.phone }
									name="phone"
									placeholder="Teléfono"
									onChange={ (e: any) => this.change(e) } />
							</div>
						</div>
						<div className="col-md-6">
							<p className="label">Teléfono alternativo</p>
							<div className="container-phone">
								<Select
									placeholder="Seleccionar"
									name="alternative_phonecode_id"
									onChange={ (e: any) => this.change(e) }
									value={ this.state.form.alternative_phonecode_id }
									options={ this.state.phonecodes.map((item: any) => {
										return {
											value: item.id,
											label: item.name
										}
									}) } />
								<Input
									type="number"
									value={ this.state.form.alternative_phone }
									name="alternative_phone"
									placeholder="Teléfono"
									onChange={ (e: any) => this.change(e) } />
							</div>
						</div>
						<div className="col-md-12">
							<Input
								label="Score (Credolab)"
								type="number"
								value={ this.state.form.score }
								name="score"
								placeholder="Score"
								onChange={ (e: any) => this.change(e) } />
						</div>
					</div>
					<div className="text-center">
						<Button type="submit" className="btn-save">
							Guardar
						</Button>
					</div>
				</form>
			</div>
		)
	}
}

export default ModalCreate;