import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Globals, Constants, Colors } from 'utils';
import { Table, Pagination, Tooltip, Input, DatePicker, Select, Modal } from 'components';
import { CleanIcon, ViewIcon, TrashIcon } from 'assets/icons';
import { PositionService, SanService } from 'services';
import moment from 'moment';
import ModalView from './modal-view';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	date: '',
	san: '',
	payments: ''
}

class Positions extends React.Component<Props> {

	timer: any = undefined;

	state = {
		header: [
			'Nº de SAN',
			'Fecha de creación',
			'Total puestos ocupados',
			'Total puestos por ocupar',
			'Pagos por posición',
			'Estatus',
			''
		],
		form: INITIAL_STATE,
		data: [],
		page: 1,
		last_page: 1,
		visible: false,
		item: null
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Gestión de Posiciones',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false,callback = () => {}) => {
		const res: any = await PositionService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD')
		});
		this.setState({
			data: res.sanes.rows,
			last_page: res.sanes.count
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	onClose = () => {
		this.setState({
			visible: false,
			item: null
		},() => {
			this.load(true);
		});
	}

	view = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			item
		});
	}

	getColor = (item: any) => {
		let color = Colors.green;

		if (item.verified == Constants.STATUS_PAYMENT.CANCELLED) {
			color = Colors.danger;
		}

		return color;
	}

	delete = async (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar el SAN?',async () => {
			await SanService.admin.delete({
				san_id: item?.id
			});	
			Globals.showSuccess("Se ha eliminado el SAN correctamente");
			this.load(true);
		});
	}

	assignPositions = async (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			item
		});
	}

	changeStatus = async (item: any) => {
		if (item?.status_payment == Constants.STATUS_PAYMENT.PENDING || item?.status_payment == Constants.STATUS_PAYMENT.IN_PROGRESS) {
			let status = '';

			switch(item.status_payment) {
				case Constants.STATUS_PAYMENT.PENDING:
					status = 'En ejecución';
				break;

				case Constants.STATUS_PAYMENT.IN_PROGRESS:
					status = 'Culminado';
				break;
			}

			Globals.confirm(`¿Esta seguro de cambiar el San a estatus "${ status }"? Esta acción no se puede deshacer`,async () => {
				await PositionService.admin.changeStatus({
					san_id: item.id
				});
				this.load(true);
			});
		}
	}
	
	render() {
		const { visible } = this.state;

		return (
			<div id="admin-positions">
				<Modal
		          className="modal-view-positions"
		          visible={ visible }
		          title="Posiciones"
		          onClose={ () => this.onClose() }
		        >
		          <ModalView
		          	item={ this.state.item }
		            onClose={ () => this.onClose() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="col-md-12">
						<div className="row">
							<div className="row col-md-10">
								<div className="col-md-3">
									<Input
										value={ this.state.form.search }
										name="search"
										placeholder="Buscar"
										label="Nombre usuario"
										className="input-table"
										onSubmit={ () => this.load() }
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load(true);
											},1000);
											this.change(e); 
										} } />
								</div>
								<div className="col-md-3">
									<DatePicker
										label="Fecha de creación"
										placeholder="Fecha"
										onChange={ (text: string) => {
											this.change({
												target: {
													value: text,
													name: 'date'
												}
											},() => this.load(true));
										} }
										value={ this.state.form.date }
									/>
								</div>
								<div className="col-md-3">
									<Input
										value={ this.state.form.san }
										name="san"
										placeholder="Buscar"
										label="Nº de SAN"
										className="input-table"
										onSubmit={ () => this.load() }
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load(true);
											},1000);
											this.change(e); 
										} } />
								</div>
								<div className="col-md-3">
									<Input
										type="number"
										value={ this.state.form.payments }
										name="payments"
										placeholder="Buscar"
										label="Pagos por posición"
										className="input-table"
										onSubmit={ () => this.load() }
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load(true);
											},1000);
											this.change(e); 
										} } />
								</div>
							</div>
							<div className="col-md-2">
								{
									this.state.form != INITIAL_STATE && (
										<button className="btn btn-clean" onClick={ () => this.reset() }>
											<img src={ CleanIcon } />
										</button>
									)
								}
							</div>
						</div>
					</div>
				</div>

				<Table title="Gestión de posiciones" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						const show_assign = i.positions?.filter((i: any) => i.position == null).length > 0 || i.quantity_persons > i.positions?.length;

						let san_pending = i.quantity_persons - i.positions?.length;

						if (san_pending < 0) {
							san_pending = 0;
						}

						const link_active = i?.status_payment == Constants.STATUS_PAYMENT.PENDING || i?.status_payment == Constants.STATUS_PAYMENT.IN_PROGRESS;

						return (
							<tr key={ index }>
								<td>{ i.code }</td>
								<td>{ moment(i.created_at).format('DD/MM/YYYY') }</td>
								<td>{ i.positions?.length }</td>
								<td>{ san_pending }</td>
								<td>{ i.type_id == Constants.SAN_TYPES.PREMIUM ? i.positions?.filter((i: any) => Constants.IMPORTANT_POSITIONS.indexOf(i.position) != -1)?.length : 0 }</td>
								<td className="status" onClick={ () => this.changeStatus(i) } style={{
									color: this.getColor(i),
									cursor: link_active ? 'pointer' : 'default'
								}}>{ i.status_payment_text }</td>
								<td style={{ 'whiteSpace': 'nowrap' }}>
									{
										i.status_payment == Constants.STATUS_PAYMENT.FINISH && (
											<Tooltip title="Eliminar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
													<img src={ TrashIcon } />
												</a>
											</Tooltip>
										)
									}
									{
										show_assign ? (
											<a className="link-icon link-assign" href="#" onClick={ (e: any) => this.assignPositions(e,i) }>
												Asignar posiciones
											</a>
										) : (
											<Tooltip title="Asignar posiciones">
												<a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
													<img src={ ViewIcon } />
												</a>
											</Tooltip>
										)
									}
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Positions);