import React, { useEffect } from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import {
  HashRouter as Router,
  Switch,
  Redirect,
  useHistory 
} from "react-router-dom";
import "moment/locale/es";
import { Route } from "components";
import { RootState } from "reducers";
import { Socket, SocketEvents, Constants, Globals } from 'utils';
import { AuthService } from 'services';
import * as Jetemit from 'jetemit';

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// Public
import Home from 'screens/home';

// Auth
import Login from "screens/auth/login/login";
import Recover from "screens/auth/login/recover";
import Reset from "screens/auth/login/reset";
import Profile from "screens/profile";
import Referral from "screens/referral";
import SuccessPassword from "screens/success-password";

// Admin Home
import Admin from 'screens/admin/home';
import AdminHome from 'screens/admin/home/home';
import AdminCommissions from 'screens/admin/commissions/commissions';
import AdminPositions from 'screens/admin/positions/positions';
import AdminPayments from 'screens/admin/payments/payments';
import AdminSan from 'screens/admin/san/san';
import AdminUsers from 'screens/admin/users/users';
import AdminReports from 'screens/admin/reports/reports';
import AdminReportPayments from 'screens/admin/reports/payments';
import AdminReportPoints from 'screens/admin/reports/points';
import AdminAccreditations from 'screens/admin/accreditations/accreditations';
import AdminAccreditationPayments from 'screens/admin/accreditations/payments';
import AdminAccreditationHistory from 'screens/admin/accreditations/history';
import AdminGlobalNotification from 'screens/admin/global-notifications/global-notifications';
import AdminStructures from 'screens/admin/structures/structures';

// Scripts
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./scss/main.scss";

const Container = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const getUser = async () => {
      const user = store.getState().user;
      if (user) {
        const res: any = await AuthService.checkUser({
          user_id: user?.id,
          withoutLoading: true
        });
        if (res.user) {
          dispatch({
            type: 'SET_USER',
            payload: res.user
          });
        }
        else {
          Globals.showError("Lo sentimos, su usuario ha sido eliminado");
          close();
        }
      }
    }
    getUser();

    const close = async () => {
      await dispatch({
        type: 'SET_USER',
        payload: null
      }); 
      history.replace('/logout');
    }

    Socket.on(SocketEvents.UPDATE_USER,(data: any) => {
      const user = store.getState().user;
      if (user != null && user?.id == data.user_id) {
        getUser();
      }
    });

    Socket.on(SocketEvents.CHATS.MESSAGE,(data: any) => {
      Jetemit.emit(SocketEvents.CHATS.MESSAGE,data);
    });

    Socket.on(SocketEvents.CHATS.DELETE,(data: any) => {
      Jetemit.emit(SocketEvents.CHATS.DELETE,data);
    });

    Socket.on(SocketEvents.CHATS.EDIT,(data: any) => {
      Jetemit.emit(SocketEvents.CHATS.EDIT,data);
    });
  },[]);

  return (
    <React.Fragment>
      <Switch>
        {/* Public */}
        <Route exact path="/" component={ Home } />
        <Route exact path="/referral" component={ Referral } />
        <Route exact path="/success-password" component={ SuccessPassword } />

        {/* Auth */}
        <Route exact noAuth path="/login" component={ Login } />
        <Route exact auth path="/profile" component={ Profile } />
        <Route exact noAuth path="/recover" component={ Recover } />
        <Route exact noAuth path="/recover/:code" component={ Reset } />

        {/* Admin */}

        {/* Home */}
        <Route exact admin path="/admin" component={ Admin } />  
        <Route exact admin path="/admin/home" component={ AdminHome } />
        <Route exact admin path="/admin/payments" component={ AdminPayments } />
        <Route exact admin path="/admin/san" component={ AdminSan } />
        <Route exact admin path="/admin/positions" component={ AdminPositions } />
        <Route exact admin path="/admin/commissions" component={ AdminCommissions } />
        <Route exact admin path="/admin/users" component={ AdminUsers } />
        <Route exact admin path="/admin/reports" component={ AdminReports } />
        <Route exact admin path="/admin/reports/points" component={ AdminReportPoints } />
        <Route exact admin path="/admin/reports/payments" component={ AdminReportPayments } />
        <Route exact admin path="/admin/accreditations" component={ AdminAccreditations } />
        <Route exact admin path="/admin/accreditations/payments" component={ AdminAccreditationPayments } />
        <Route exact admin path="/admin/accreditations/history" component={ AdminAccreditationHistory } />
        <Route exact admin path="/admin/global-notifications" component={ AdminGlobalNotification } />
        <Route exact admin path="/admin/structures" component={ AdminStructures } />

        <Redirect to="/" />
      </Switch>
    </React.Fragment>
  )
}

const App = () => (
  <Provider store={ store }>
    <PersistGate loading={ null } persistor={ persistor }>
      <Router>
        <Container />
      </Router>
    </PersistGate>
  </Provider>
);

export default App;
