import React from 'react';
import { Input, Button, Select } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Constants, Globals } from 'utils';
import { AccreditationService } from 'services';
import { CheckWhiteIcon } from 'assets/icons';

interface Props {
	onClose?: any;
}

class ModalConfig extends React.Component<Props> {

	state = {
		form: {
			auto_accreditations: Constants.AUTOACCREDITATIONS.NO
		}
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await AccreditationService.admin.getConfig();
		this.setState({
			form: {
				...this.state.form,
				auto_accreditations: res.config?.auto_accreditations || Constants.AUTOACCREDITATIONS.NO
			}
		});
	}

	submit = async () => {
		await AccreditationService.admin.saveConfig({
			...this.state.form
		});
		this.props.onClose();
		Globals.showSuccess("Se ha guardado la configuración correctamente");
	}
	
	render() {
		return (
			<div id="modal-view-config">
				<div className="check-container" onClick={ () => {
					this.setState({
						form: {
							...this.state.form,
							auto_accreditations: !this.state.form.auto_accreditations
						}
					});
				} }>
					<div className={ `check ${ this.state.form.auto_accreditations == Constants.AUTOACCREDITATIONS.YES ? 'active' : '' }` }>
						{
							this.state.form.auto_accreditations == Constants.AUTOACCREDITATIONS.YES && (
								<img src={ CheckWhiteIcon } />
							)
						}
					</div>
					<p>Autoacreditaciones</p>
				</div>
				<div className="text-center">
					<Button type="submit" className="btn-save" onClick={ () => this.submit() }>
						Guardar
					</Button>
				</div>
			</div>
		)
	}
}

export default ModalConfig;