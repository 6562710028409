import { Api } from 'utils';

class StructureService {
		
	static admin = {
		create: async (params?: any) => Api.createResponse('admin/san-structure/create',params),
		update: async (params?: any) => Api.createResponse('admin/san-structure/update',params),
		get: async (params?: any) => Api.createResponse('admin/san-structure',params),
		delete: async (params?: any) => Api.createResponse('admin/san-structure/delete',params),
	}

}

export default StructureService;