import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Globals, Constants, Colors, Print } from 'utils';
import { Table, Pagination, Tooltip, Input, DatePicker, Select, Modal } from 'components';
import { CleanIcon, EditIcon, TrashIcon, DownloadExcelIcon, GoogleMapsIcon, BankIcon, DocumentIcon, ViewIcon } from 'assets/icons';
import { UserService } from 'services';
import moment from 'moment';
import ModalCreate from './modal-create';
import ModalValidation from './modal-validation';
import ModalLocation from './modal-location';
import ModalBank from './modal-bank';
import ModalCredolab from './modal-credolab';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	since: '',
	until: '',
	credolab_id: '',
	since_score: '',
	until_score: ''
}

declare var window: any;

class Users extends React.Component<Props> {

	timer: any = undefined;

	state = {
		header: [
			'Fecha',
			'Nombre',
			'Nº documento',
			'Teléfono',
			// 'Fecha Nacimiento',
			'Score',
			'Correo',
			'CredoLab',
			'Ubicación',
			// 'Documentos',
			'Identidad',
			''
		],
		form: INITIAL_STATE,
		data: [],
		page: 1,
		last_page: 1,
		visible: false,
		item: null,
		visible_location: false,
		visible_validation: false,
		visible_bank: false,
		visible_credolab: false,
		credolab_accepted: 0
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Validación de Usuarios',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false,callback = () => {}) => {
		const res: any = await UserService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
			until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD')
		});
		this.setState({
			data: res.users.rows,
			last_page: res.users.count,
			credolab_accepted: res.credolab_accepted
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			item
		});
	}

	delete = async (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar el usuario?',async () => {
			await UserService.admin.delete({
				user_id: item?.id
			});	
			Globals.showSuccess("Se ha eliminado el usuario correctamente");
			this.load(true);
		});
	}

	onClose = () => {
		this.setState({
			visible: false,
			item: null
		},() => {
			this.load(true);
		});
	}

	getColor = (item: any) => {
		let color = Colors.green;

		if (item.verified == Constants.VERIFIED_STATUS.PENDING) {
			color = Colors.orange;
		}
		else if (item.verified == Constants.VERIFIED_STATUS.REJECTED) {
			color = Colors.danger;
		}

		return color;
	}

	download = async () => {
		const res: any = await UserService.admin.downloadExcel({
			...this.state.form,
			since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
			until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD')
		});
		if (window.webkit && window.webkit.messageHandlers) {
			const message = 'open_url';
			const sendToNative = {
				message,
				url: Constants.BASE_STORAGE + res.url
			}
			const stringifiedMessageObj = JSON.stringify(sendToNative);
	        window.webkit.messageHandlers.cordova_iab.postMessage(stringifiedMessageObj);
	    }
	    else {
	    	Print(Constants.BASE_STORAGE + res.url);
	    }
	}

	openValidation = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible_validation: true,
			item
		});
	}

	closeValidation = () => {
		this.setState({
			visible_validation: false
		},() => {
			this.load(true);
		});
	}

	openLocation = (item: any) => {
		this.setState({
			visible_location: true,
			item
		});
	}

	closeLocation = () => {
		this.setState({
			visible_location: false
		});
	}

	viewBank = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible_bank: true,
			item
		});
	}

	onCloseBank = () => {
		this.setState({
			item: null,
			visible_bank: false
		},() => this.load(true));
	}

	onCloseCredolab = () => {
		this.setState({
			visible_credolab: false
		});
	}

	openCredolab = () => {
		this.setState({
			visible_credolab: true
		});
	}
	
	render() {
		const { visible, visible_location, visible_validation, visible_bank, visible_credolab, credolab_accepted } = this.state;

		return (
			<div id="admin-users">
				<Modal
		          className="modal-create-user"
		          visible={ visible }
		          title="Editar usuario"
		          onClose={ () => this.onClose() }
		        >
		          <ModalCreate
		          	item={ this.state.item }
		            onClose={ () => this.onClose() } />
		      	</Modal>

		      	<Modal
		          className="modal-view-bank"
		          visible={ visible_bank }
		          title="Ver Datos Bancarios"
		          onClose={ () => this.onCloseBank() }
		        >
		          <ModalBank
		          	item={ this.state.item }
		            onClose={ () => this.onCloseBank() } />
		      	</Modal>

		      	<Modal
		          className="modal-credolab"
		          visible={ visible_credolab }
		          title="Configuración de Credolab"
		          onClose={ () => this.onCloseCredolab() }
		        >
		          <ModalCredolab
		            onClose={ () => this.onCloseCredolab() } />
		      	</Modal>

		      	<Modal
		          className="modal-users-google-maps"
		          visible={ visible_location }
		          title="Ubicación"
		          onClose={ () => this.closeLocation() }
		        >
		          <ModalLocation
		          	item={ this.state.item }
		            onClose={ () => this.closeLocation() } />
		      	</Modal>

		      	<Modal
		          className="modal-users-valid"
		          visible={ visible_validation }
		          title="Documentos"
		          onClose={ () => this.closeValidation() }
		        >
		          <ModalValidation
		          	item={ this.state.item }
		          	reload={ async () => {
		          		let item: any = this.state.item;
		          		item.verified = Constants.VERIFIED_STATUS.REJECTED;
		          		await this.setState({
		          			item
		          		});
		          		this.load(true);
		          	} }
		            onClose={ () => this.closeValidation() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="col-md-12">
						<div className="row">
							<div className="row col-md-6">
								<div className="col-md-6">
									<Input
										value={ this.state.form.search }
										name="search"
										placeholder="Buscar"
										label="Nombre del usuario"
										className="input-table"
										onSubmit={ () => this.load() }
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load(true);
											},1000);
											this.change(e); 
										} } />
								</div>
								<div className="col-md-6">
									<Input
										value={ this.state.form.credolab_id }
										name="credolab_id"
										placeholder="Buscar por código"
										label="Cód. CredoLab"
										className="input-table"
										onSubmit={ () => this.load() }
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load(true);
											},1000);
											this.change(e); 
										} } />
								</div>
							</div>
							<div className="row col-md-6">
								<div className="col-md-12">
									<p className="label">Fecha de Registro</p>
								</div>
								<div className="col-md-6">
									<DatePicker
										showYearDropdown={ true }
										placeholder="Desde"
										onChange={ (text: string) => {
											this.change({
												target: {
													value: text,
													name: 'since'
												}
											},() => this.load(true));
										} }
										value={ this.state.form.since }
									/>
								</div>
								<div className="col-md-6">
									<DatePicker
										showYearDropdown={ true }
										placeholder="Hasta"
										onChange={ (text: string) => {
											this.change({
												target: {
													value: text,
													name: 'until'
												}
											},() => this.load(true));
										} }
										value={ this.state.form.until }
									/>
								</div>
							</div>
							<div className="row col-md-6">
								<div className="col-md-12">
									<p className="label" style={{ marginBottom: '5px' }}>Rango Score</p>
								</div>
								<div className="col-md-6">
									<Input
										type="number"
										value={ this.state.form.since_score }
										name="since_score"
										placeholder="Desde"
										className="input-table"
										onSubmit={ () => this.load() }
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load(true);
											},1000);
											this.change(e); 
										} } />
								</div>
								<div className="col-md-6">
									<Input
										type="number"
										value={ this.state.form.until_score }
										name="until_score"
										placeholder="Hasta"
										className="input-table"
										onSubmit={ () => this.load() }
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load(true);
											},1000);
											this.change(e); 
										} } />
								</div>
							</div>
							<div className="col-md-2">
								{
									this.state.form != INITIAL_STATE && (
										<button className="btn btn-clean" onClick={ () => this.reset() }>
											<img src={ CleanIcon } />
										</button>
									)
								}
							</div>
						</div>
					</div>
				</div>

				<button className="btn btn-credolab" onClick={ () => this.openCredolab() }>
					Configuración de Credolab
				</button>

				<Table title="Usuarios Registrados" data={ this.state.data.length } header={ this.state.header } titleRight={
					<React.Fragment>
						{
							this.state.data.length > 0 && (
								<div className="container-add-table">
									<p>Descargar Excel</p>
									<button className="btn add-btn-table" onClick={ this.download }>
										<img src={ DownloadExcelIcon } />
									</button>
								</div>
							)
						}
					</React.Fragment>
				}>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.created_at ? moment(i.created_at).format('DD/MM/YYYY') : '-' }</td>
								<td>{ i.name + ' ' + i.lastname }</td>
								<td style={{ whiteSpace: 'nowrap' }}>{ ((i.document_type?.name || '') + (i.document_type_id == Constants.DOCUMENT_TYPES.PASSPORT ? ' - ' : '')) + i.document }</td>
								<td>
									{
										i.phone && (
											<p>{ (i.phonecode?.name || '') + i.phone }</p>
										)
									}
									{
										i.person?.alternative_phone && (
											<p>{ (i.person?.alternative_phonecode?.name || '') + i.person?.alternative_phone }</p>
										)
									}
								</td>
								{/*<td>{ i.birthdate ? moment(i.birthdate).format('DD/MM/YYYY') : '' }</td>*/}
								<td className={ i.credolab_score ? (credolab_accepted <= i.credolab_score ? 'credolab-active' : 'credolab-rejected') : '' }>{ i.credolab_score || '-' }</td>
								<td>{ i.email }</td>
								<td>{ i.credolab_id || '-' }</td>
								<td>
									<button className="btn btn-google-maps" onClick={ () => this.openLocation(i) }>
										<img src={ GoogleMapsIcon } className="google-maps-icon" />
									</button>
								</td>
								<td className="status" style={{
									color: this.getColor(i)
								}}>{ i.status_verified }</td>
								<td style={{ 'whiteSpace': 'nowrap' }}>
									<Tooltip title="Ver">
										<a className="link-icon" href="#" onClick={ (e: any) => this.openValidation(e,i) }>
											<img src={ DocumentIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Datos Bancarios">
										<a className="link-icon" href="#" onClick={ (e: any) => this.viewBank(e,i) }>
											<img src={ BankIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Editar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
											<img src={ EditIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Eliminar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
											<img src={ TrashIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Users);