import React from 'react';
import { Input, Button, Select, Textarea } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { GlobalNotificationService } from 'services';

interface Props {
	onClose?: any;
	types?: any;
}

class SendGlobalNotification extends React.Component<Props> {

	state = {
		form: {
			message: '',
			type_id: '',
			way: ''
		}
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}
	
	submit = async () => {
		await GlobalNotificationService.admin.send({
			...this.state.form,
			// @ts-ignore
			type_id: parseInt(this.state.form.type_id),
			way: parseInt(this.state.form.way)
		});
		this.props.onClose();
		Globals.showSuccess("Se ha enviado la notificación correctamente");
	}

	render() {
		return (
			<div id="send-global-notification">
				<Select
					placeholder="Seleccionar"
					label="Tipo de Notificación"
					name="type_id"
					onChange={ this.change }
					value={ this.state.form.type_id }
					options={ this.props.types.map((item: any) => {
						return {
							value: item.id,
							label: item.name
						}
					}) } />
				<Select
					placeholder="Seleccionar"
					label="Canal"
					name="way"
					onChange={ this.change }
					value={ this.state.form.way }
					options={ [
						{ value: Constants.GLOBAL_NOTIFICATION_WAYS.PUSH.toString(), label: 'Notificación Push' },
						{ value: Constants.GLOBAL_NOTIFICATION_WAYS.SMS.toString(), label: 'SMS' },
						{ value: Constants.GLOBAL_NOTIFICATION_WAYS.BOTH.toString(), label: 'Ambos' }
					] } />
				<Textarea
					rows={ 2 }
					value={ this.state.form.message }
					placeholder="Escribir"
					label="Mensaje"
					name="message"
					onChange={ (e: any) => this.change(e) } />
				<div className="text-center">
					<button className="btn btn-edit" onClick={ () => this.submit() }>
						Guardar
					</button>
				</div>
			</div>
		)
	}
}

export default SendGlobalNotification;