import React from 'react';
import { Input, Button, Select, Textarea } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { GlobalNotificationService } from 'services';

interface Props {
	onClose?: any;
}

class ModalConfig extends React.Component<Props> {

	state = {
		reminder_texts: []
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await GlobalNotificationService.admin.getReminderTexts();
		this.setState({
			reminder_texts: res.reminder_texts || []
		});
	}
	
	submit = async () => {
		if (this.state.reminder_texts.find((i: any) => i.text == '' || i.text == null)) {
			Globals.showError("Debe ingresar todos los mensajes para continuar");
			return;
		}

		await GlobalNotificationService.admin.saveReminderTexts({
			reminder_texts: JSON.stringify(this.state.reminder_texts)
		});
		this.props.onClose();
		Globals.showSuccess("Se ha guardado la configuración correctamente");
	}

	changeConfig = (index: number, e: any) => {
		let reminder_texts: any = [...this.state.reminder_texts];
		reminder_texts[index][e.target.name] = e.target.value;
		this.setState({
			reminder_texts
		});
	}

	render() {
		return (
			<div id="modal-reminder-config">
				{
					this.state.reminder_texts.map((item: any, index: number) => {
						return (
							<Textarea
								rows={ 2 }
								value={ item.text }
								placeholder="Escribir"
								name="text"
								label={ item?.name }
								onChange={ (e: any) => this.changeConfig(index,e) } />
						)
					})
				}
				<div className="text-center">
					<button className="btn btn-edit" onClick={ () => this.submit() }>
						Guardar
					</button>
				</div>
			</div>
		)
	}
}

export default ModalConfig;