import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Globals, Constants, Colors, Print } from 'utils';
import { Table, Pagination, Tooltip, Input, DatePicker, Select, Modal } from 'components';
import { SendIcon, CleanIcon } from 'assets/icons';
import { GlobalNotificationService } from 'services';
import moment from 'moment';
import ModalGlobalNotification from './send-global-notification';
import ModalConfig from './modal-config';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	since: '',
	until: '',
	type_id: '',
	way: ''
}

class GlobalNotifications extends React.Component<Props> {

	state = {
		header: [
			'Fecha',
			'Mensaje',
			'Canal',
			'Tipo'
		],
		form: INITIAL_STATE,
		data: [],
		page: 1,
		last_page: 1,
		visible_global_notification: false,
		types: [],
		visible_config: false
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Notificaciones',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.loadTypes();
		this.load();
	}

	load = async (withoutLoading: boolean = false,callback = () => {}) => {
		const res: any = await GlobalNotificationService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
			until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD'),
		});
		this.setState({
			data: res.notifications.rows,
			last_page: res.notifications.count
		});
	}

	loadTypes = async () => {
		const res: any = await GlobalNotificationService.admin.getTypes({
			withoutLoading: true
		});
		this.setState({
			types: res.types || []
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	onCloseGlobalNotification = () => {
		this.setState({
			visible_global_notification: false
		},() => this.load(true));
	}

	openNotification = () => {
		this.setState({
			visible_global_notification: true
		});
	}

	openConfig = () => {
		this.setState({
			visible_config: true
		});
	}

	onCloseConfig = () => {
		this.setState({
			visible_config: false
		});
	}
	
	render() {
		const { visible_global_notification, visible_config } = this.state;

		return (
			<div id="admin-global-notifications">
		      	<Modal
		          className="send-global-notification"
		          visible={ visible_global_notification }
		          title="Enviar notificación"
		          onClose={ () => this.onCloseGlobalNotification() }
		        >
		          <ModalGlobalNotification
		          	types={ this.state.types }
		            onClose={ () => this.onCloseGlobalNotification() } />
		      	</Modal>

		      	<Modal
		          className="modal-reminder-config"
		          visible={ visible_config }
		          title="Configuración de Recordatorios"
		          onClose={ () => this.onCloseConfig() }
		        >
		          <ModalConfig
		            onClose={ () => this.onCloseConfig() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="col-md-12">
						<div className="row">
							<div className="row col-md-11">
								<div className="col-md-3">
									<Select
										placeholder="Seleccionar"
										label="Tipo de Notificación"
										name="type_id"
										onChange={ (e: any) => this.change(e,async () => {
											this.load(true);
										}) }
										value={ this.state.form.type_id }
										options={ this.state.types.map((item: any) => {
											return {
												value: item.id,
												label: item.name
											}
										}) } />
								</div>
								<div className="col-md-3">
									<Select
										placeholder="Seleccionar"
										label="Canal"
										name="way"
										onChange={ (e: any) => this.change(e,async () => {
											this.load(true);
										}) }
										value={ this.state.form.way }
										options={ [
											{ value: Constants.GLOBAL_NOTIFICATION_WAYS.PUSH.toString(), label: 'Notificación Push' },
											{ value: Constants.GLOBAL_NOTIFICATION_WAYS.SMS.toString(), label: 'SMS' },
											{ value: Constants.GLOBAL_NOTIFICATION_WAYS.BOTH.toString(), label: 'Ambos' }
										] } />
								</div>
								<div className="col-md-3">
									<DatePicker
										showYearDropdown={ true }
										label="Desde"
										onChange={ (text: string) => {
											this.change({
												target: {
													value: text,
													name: 'since'
												}
											},() => this.load(true));
										} }
										value={ this.state.form.since }
									/>
								</div>
								<div className="col-md-3">
									<DatePicker
										showYearDropdown={ true }
										label="Hasta"
										onChange={ (text: string) => {
											this.change({
												target: {
													value: text,
													name: 'until'
												}
											},() => this.load(true));
										} }
										value={ this.state.form.until }
									/>
								</div>
							</div>
							<div className="col-md-1">
								{
									this.state.form != INITIAL_STATE && (
										<button className="btn btn-clean" onClick={ () => this.reset() }>
											<img src={ CleanIcon } />
										</button>
									)
								}
							</div>
						</div>
					</div>
				</div>

				<button className="btn btn-config" onClick={ () => this.openConfig() }>
					Configuración de Recordatorios
				</button>

				<Table title="Notificaciones" data={ this.state.data.length } header={ this.state.header } titleRight={
					<div className="container-add-table">
						<p>Enviar</p>
						<button className="btn add-btn-table" onClick={ () => this.openNotification() }>
							<img src={ SendIcon } />
						</button>
					</div>
				}>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ moment(i.created_at).format('DD/MM/YYYY HH:mm') }</td>
								<td style={{ maxWidth: '200px' }}>{ i.message }</td>
								<td>{ i.way_text }</td>
								<td>{ i?.type?.name || '-' }</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(GlobalNotifications);