import React from 'react';
import { Input, Button, Select } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { SanService } from 'services';

interface Props {
	onClose?: any;
	item?: any;
}

const FREQUENCIES = Constants.FREQUENCIES;

class ModalCreate extends React.Component<Props> {

	state = {
		form: {
			state_id: '',
			city_id: '',
			amount: '',
			dues: '',
			frequency: '',
			quantity_persons: '',
			days_max: '0',
			points: '0',
			days_or_weeks: '',
			type_id: Constants.SAN_TYPES.PREMIUM
		},
		states: [],
		cities: [],
		types: []
	}

	componentDidMount() {
		this.load();

		if (this.props.item) {
			const item: any = this.props.item;

			this.setState({
				form: {
					...this.state.form,
					state_id: item.state_id || '',
					city_id: item.city_id || '',
					amount: item.amount || '',
					dues: item.dues || '',
					frequency: item.frequency || '',
					quantity_persons: item.quantity_persons || '',
					days_max: item.days_max || '0',
					points: item.points || '0',
					days_or_weeks: item.days_or_weeks || '',
					type_id: item.type_id || ''
				}
			},() => this.loadCities());
		}
	}

	load = async () => {
		const res: any = await SanService.admin.getStates();
		this.setState({
			states: res.states,
			types: res.types || []
		});
	}

	submit = async (e: any) => {
		e.preventDefault();

		if (this.props.item) {
			await SanService.admin.update({
				...this.state.form,
				san_id: this.props.item?.id
			});
			this.props.onClose();
			Globals.showSuccess("Se ha modificado el SAN correctamente");
		}
		else {
			await SanService.admin.create({
				...this.state.form
			});
			this.props.onClose();
			Globals.showSuccess("Se ha registrado el SAN correctamente");
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	loadCities = async () => {
		const res: any = await SanService.admin.getCities({
			state_id: this.state.form.state_id
		});
		this.setState({
			cities: res.cities
		});
	}
	
	render() {
		return (
			<div id="modal-create-san">
				<form onSubmit={ this.submit }>
					<div className="row">
						<div className="col-md-12">
							<Select
								placeholder="Seleccionar"
								label="Tipo de San"
								name="type_id"
								onChange={ this.change }
								value={ this.state.form.type_id }
								options={ this.state.types.map((item: any) => {
									return {
										value: item.id,
										label: item.name
									}
								}) } />
						</div>
						<div className="col-md-6">
							<Select
								placeholder="Seleccionar"
								label="Estado (Opcional)"
								name="state_id"
								onChange={ (e: any) => this.change(e,async () => {
									await this.setState({
										form: {
											...this.state.form,
											city_id: ''
										}
									});
									this.loadCities();
								}) }
								value={ this.state.form.state_id }
								options={ this.state.states.map((item: any) => {
									return {
										value: item.id,
										label: item.name
									}
								}) } />
						</div>
						<div className="col-md-6">
							<Select
								disabled={ !this.state.form.state_id }
								placeholder="Seleccionar"
								label="Ciudad (Opcional)"
								name="city_id"
								onChange={ (e: any) => this.change(e) }
								value={ this.state.form.city_id }
								options={ this.state.cities.map((item: any) => {
									return {
										value: item.id,
										label: item.name
									}
								}) } />
						</div>
						<div className="col-md-6">
							<Input
								type="decimal"
								value={ this.state.form.amount }
								name="amount"
								placeholder="Monto"
								label="Monto del San (US$)"
								onChange={ (e: any) => this.change(e) } />
						</div>
						<div className="col-md-6">
							<Input
								type="decimal"
								value={ this.state.form.dues }
								name="dues"
								placeholder="Monto"
								label="Cuota a cancelar (US$)"
								onChange={ (e: any) => this.change(e) } />
						</div>
						<div className="col-md-12">
							<p className="frequency-label">Frecuencia</p>
						</div>
						<div className="col-md-12">
							<div className="container-checks">
								<div className="item-check" onClick={ () => {
									this.setState({
										form: {
											...this.state.form,
											frequency: FREQUENCIES.WEEK
										}
									});
								} }>
									{/*
									// @ts-ignore */}
									<div className={ `check ${ this.state.form.frequency == FREQUENCIES.WEEK ? 'active' : '' }` }></div>
									<p>Semanal</p>
								</div>
								<div className="item-check" onClick={ () => {
									this.setState({
										form: {
											...this.state.form,
											frequency: FREQUENCIES.MONTH
										}
									});
								} }>
									{/*
									// @ts-ignore */}
									<div className={ `check ${ this.state.form.frequency == FREQUENCIES.MONTH ? 'active' : '' }` }></div>
									<p>Mensual</p>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<Input
								type="number"
								value={ this.state.form.days_or_weeks }
								name="days_or_weeks"
								placeholder="0"
								label="Nº de semanas/meses"
								onChange={ (e: any) => this.change(e) } />
						</div>
						<div className="col-md-6">
							<Input
								type="number"
								value={ this.state.form.quantity_persons }
								name="quantity_persons"
								placeholder="0"
								label="Nº de personas participantes"
								onChange={ (e: any) => this.change(e) } />
						</div>
						{/*<div className="col-md-8 container-gray">
							<p>Días max. para pago después de corte</p>
						</div>
						<div className="col-md-4 container-gray">
							<Input
								type="number"
								value={ this.state.form.days_max }
								name="days_max"
								placeholder="0"
								onChange={ (e: any) => this.change(e) } />
						</div>
						<div className="col-md-12">
							<div className="separator"></div>
						</div>
						<div className="col-md-8 container-gray">
							<p>Puntos a acreditar por puntualidad</p>
						</div>
						<div className="col-md-4 container-gray">
							<Input
								type="number"
								value={ this.state.form.points }
								name="points"
								placeholder="0"
								onChange={ (e: any) => this.change(e) } />
						</div>*/}
					</div>
	      			<div className="text-center">
						<Button type="submit">
							Crear SAN
						</Button>
					</div>
				</form>
			</div>
		)
	}
}

export default ModalCreate;